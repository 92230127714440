@import '../../styles/fonts';
@import '../../styles/variables';
@import url('//fonts.googleapis.com/earlyaccess/nanummyeongjo.css');

*{
    margin : 0;
    padding : 0;
    box-sizing : border-box;
}

.container{
    width: 100%;
    height : 640px;
}


.imageWrapper{
    background-image: url('../../../public/MainLayoutImage/mainBackgroundImage.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
    width: 100%;
    height: 640px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}   

.contentWrapper2{
    display: flex;
    width : 1292.5px;
    height : 637px;
    flex-direction: row;
    justify-content: space-between;
}

.messageWrapper{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-bottom: 115px;
    > div {
        display: flex;
        text-align: center;
        text-shadow:
            -2px -2px 0 #353535,
            0   -2px 0 #353535,
            2px -2px 0 #353535,
            2px  0   0 #353535,
            2px  2px 0 #353535,
            0    2px 0 #353535,
            -2px  2px 0 #353535,
            -2px  0   0 #353535;
        color : white;
        font-family: "MaruBuri" ,Georgia, 'Times New Roman', Times, serif;
        font-weight: 700;

        
        &:first-of-type {
          font-size: 52px;
        }
    
        &:last-of-type {
          font-family: "Pretendard-Medium", Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
          font-size: 30px;
          margin-top: 20px;
          font-weight: 600;
        }
    }
}


.btnContainer{
    margin-top: 103.5px;
    height: 468px;
}

.btnWrapper{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap:20px;
}

.first{
    width : 600px;  
    height : 143px;
    background-color: #4095A2;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.two{
    width : 600px;
    height : 143px;
    background-color: #775D34;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.three{
    width : 600px;
    height : 143px;
    background-color: #72732C;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.iconWrapper {
    background-image: url('../../../public/MainLayoutImage/mainCall.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100px;
    height: 100px;
    margin-left: 40px;
    margin-right: 40px;
}
.iconWrapper2{
    background-image : url('../../../public/MainLayoutImage/mainIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100px;
    height: 100px;
    margin-left: 40px;
    margin-right: 40px;
}
.iconWrapper3{
    background-image : url('../../../public/MainLayoutImage/mainFlower.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100px;
    height: 100px;
    margin-left: 40px;
    margin-right: 40px;
}

.stringWrapper{
    color : white;
    font-size: 24px;
    font-family: 'Pretendard-SemiBold';
    font-weight: 600;
    margin-top : 6px;
}

.comment{
    margin-left: 1px;
    margin-bottom: 2px;
}

.numberWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}
.numberIcon{
    background-image : url('../../../public/MainLayoutImage/icon.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
}



@media (min-width: 1024px) {
    .first, .two, .three {
        transition: transform 0.2s ease;
        &:hover {
            transform: translateY(-4px);
        }
    }
    
}

// 모바일 화면
@media (max-width: 1023px) {

    .container{
        width : 100%;
        height : 481px;
    }
    .numberWrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0;
        }
    .imageWrapper {
        width: 100%;
        height: 200px;
        background-image: url('../../../public/MainLayoutImage/mobile/mainPageImage.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; 

        display: block;

        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
    }

    .contentWrapper2{
        display: flex;
        flex-direction: column;
        width : 100%;
        height : 200px;
    }
    .iconWrapper{
        background-image : url('../../../public/MainLayoutImage/mainCall.svg');
        margin-left: 15px;
        margin-top: 11px;
        margin-right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 48px;
        height: 48px;
    }
    .iconWrapper2{
        background-image : url('../../../public/MainLayoutImage/mainIcon.svg');
        margin-left: 15px;
        margin-top: 11px;
        margin-right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 48px;
        height: 48px;
    }
    .iconWrapper3{
        background-image : url('../../../public/MainLayoutImage/mainFlower.svg');
        margin-left: 15px;
        margin-top: 11px;
        margin-right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 48px;
        height: 48px;
    }

    
    .messageWrapper{
        width : 201px;
        height : 36px;
        display: flex;
        flex-direction: column;
        margin-top: 127px;
        margin-left: 25px;
        margin-bottom: 53px;
        justify-content: center;
        align-items: center;
        gap:5px;

        > div {
            display: flex;
            text-align: center;
            text-shadow:
                -1px -1px 0 #353535,
                0   -1px 0 #353535,
                1px -1px 0 #353535,
                1px  0   0 #353535,
                1px  1px 0 #353535,
                0    1px 0 #353535,
                -1px  1px 0 #353535,
                -1px  0   0 #353535;
            color : #ffffff;
            font-family: "MaruBuri" ,Georgia, 'Times New Roman', Times, serif;
            font-weight: 700;
        
            &:first-of-type {
                width: 350px;
                padding-left: 80px;
                font-size: 20px;
            }
        
            &:last-of-type {
                width: 350px;
                padding-left: 100px;
                font-family: "Pretendard-Medium", Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                font-size: 14px;
                margin-top: 0px;
                font-weight: 600;
            }
        }
    }

    .btnContainer{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 310px;
        margin-top: 0px;

        .btnWrapper{ 
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            width: 330px;
            gap: 15px;  
            padding-bottom: 25px;
            vertical-align: middle;

            .first{
                width : 330px;  
                height : 70px;
                background-color: #4095A2;
                display: flex;
                align-items: normal;
        
                .stringWrapper{
                    height: 60.27px;
                    color : #ffffff;
                    font-size: 14px;
                    margin-left: 20px;
                    margin-top : 4.87px;
                    line-height: 16.71px;
                    margin-right: 54px;
            
                    .comment{
                        width : 174px;
                        margin-left: 0px;
                        margin-bottom: 0px;
                        font-family: Pretendard-SemiBold;
                    }
                }
            }

            .two{
                width : 330px;  
                height : 70px;
                background-color: #775D34;
                display: flex;
                align-items: normal;
        
                
                .stringWrapper{
                    width: 179px;
                    height: 60.27px;
                    color : #ffffff;
                    font-size: 14px;
                    margin-left: 20px;
                    margin-top : 4.87px;
                    line-height: 16.71px;
                    margin-right: 0px;
            
                    .comment{
                        width : 179px;
                        margin-left: 0px;
                        margin-bottom: 0px;
                        font-family: Pretendard-SemiBold;
                    }
                }
            
            }
            .three{
                width : 330px;  
                height : 70px;
                background-color: #72732C;
                display: flex;
                align-items: normal;
        
                
                .stringWrapper{
                    width: 230px;
                    height: 60.27px;
                    color : #ffffff;
                    font-size: 14px;
                    margin-left: 20px;
                    margin-top : 4.87px;
                    line-height: 16.71px;
                    margin-right: 0px;
            
                    .comment{
                        width : 230px;
                        margin-left: 0px;
                        margin-bottom: 0px;
                        font-family: Pretendard-SemiBold;
                    }
                }
            }
        }
    }
}